<template>

  <div v-if="companyLogo" class="relative">
    <div
      @click="removeLogo"
      class="h-6 w-6 flex justify-center items-center absolute top-3 left-3 border border-il-dark bg-il-trans-black text-white hover:bg-il-dark transition-all ease-in-out duration-200 rounded-lg cursor-pointer"
    >
      <div class="-ml-px mt-px">
        <i class="fas fa-times"></i>
      </div>
    </div>
    <img
      :src="companyLogo"
      class="p-2 max-w-200px max-h-150px overflow-hidden"
    />
  </div>

  <div v-else>
    <input
      @change="uploadLogo"
      type="file"
      name="photo"
      accept="image/*"
      ref="_logoImage"
      class="invisible w-0 h-0 absolute top-0 left-0"
    >
    <div
      class="w-200px h-150px flex justify-center items-center bg-gray-200 transition-all ease-in-out duration-200 cursor-pointer uppercase text-xs"
      @click="triggerLogoUpload"
    >
      <span>
        Add Logo
      </span>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: ['companyLogo'],
  emits: [
    'update:companyLogo'
  ],
  data() {
    return {

    }
  },
  methods: {
    ...mapMutations([

    ]),
    triggerLogoUpload() {
      this.$refs._logoImage.click();
    },

    uploadLogo() {
      var that = this;
      var reader = new FileReader();
      var imageWidth = 0;
      var imageHeight = 0;
      var imageData = null;

      reader.onload = function(e) {
        var image = new Image();
        imageData = e.target.result;
        image.src = imageData;
        image.onload = function() {
          imageWidth  = image.width;
          imageHeight = image.height;

          const sizes = that.getMaxSizeLogo(imageWidth, imageHeight);
          that.resizeLogo(imageData, sizes.width, sizes.height);
        };
      };
      reader.onerror = function(error) {
        alert(error);
      };

      reader.readAsDataURL(document.querySelector('input[type=file]').files[0]);

    },
    getMaxSizeLogo(currW, currH) {
      var maxWidth = 200; // Max width for the image
      var maxHeight = 150;    // Max height for the image
      var ratio = 0;  // Used for aspect ratio
      var width = currW;    // Current image width
      var height = currH;  // Current image height

      // Check if the current width is larger than the max
      if(width > maxWidth){
          ratio = maxWidth / width;   // get ratio for scaling image
          height = height * ratio;    // Reset height to match scaled image
          width = width * ratio;    // Reset width to match scaled image
      }
      // Check if current height is larger than max
      if(height > maxHeight){
          ratio = maxHeight / height; // get ratio for scaling image
          width = width * ratio;    // Reset width to match scaled image
          height = height * ratio;    // Reset height to match scaled image
      }

      return {
        "height": height,
        "width": width
      }
    },
    resizeLogo(imageData, w, h)
    {
      // We create an image to receive the Data URI
      var img = document.createElement('img');
      var that = this;

      // When the event "onload" is triggered we can resize the image.
      img.onload = function()
        {
          // We create a canvas and get its context.
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');

          // We set the dimensions at the wanted size.
          canvas.width = w;
          canvas.height = h;

          // We resize the image with the canvas method drawImage();
          ctx.drawImage(this, 0, 0, w, h);

          var dataURI = canvas.toDataURL();

          that.updateValue('companyLogo', dataURI);
      };

      // We put the Data URI in the image's src attribute
      img.src = imageData;
    },
    removeLogo() {
      this.updateValue('companyLogo', null);
    },
    updateValue(prop,value) {
      this.$emit('update:'+prop,value);
    }
  },
  computed: {
    ...mapGetters([

    ]),
  }
}
</script>