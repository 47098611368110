<template>
  <div class="flex flex-col w-full m-8 bg-white rounded-lg p-4 relative">

    <div
      @click="navigateToMX('company-dashboard')"
      class="absolute flex items-center left-4 top-4 text-il-dark-faded hover:text-il-dark duration-200 transition-all ease-in-out cursor-pointer"
    >
      <i class="fas fa-chevron-left fa-2x"></i>
      <span class="ml-4">Back to Company Dashboard</span>
    </div>

    <div
      class="flex justify-center text-2xl text-il-dark font-bold mt-8"
      v-if="!editingCompanyName"
    >
      <div class="flex w-300px text-center items-center">
        <div class="flex-grow">
          {{ getUserCurrentCompanyX.name }}
        </div>
        <div
          class="flex-shrink text-sm text-gray-300 hover:text-il-dark cursor-pointer"
          @click="companyNameEditToggle"
        >
          <i class="fas fa-edit"></i>
        </div>
      </div>
    </div>

    <div
      v-else
      class="flex justify-center text-2xl text-il-dark font-bold mt-8"
    >
      <div class="flex w-300px text-center items-center">
        <div class="flex-grow">
          <input
            ref="companyNewNameInput"
            type="text"
            class="rounded-l-lg border border-gray-400 focus:border-il-dark outline-none p-2 py-0 font-bold text-center"
            v-model="companyNewName"
            :readonly="updateLoading"
            @keyup.enter="updateCompanyName"
          >
        </div>

        <div
          v-if="!updateLoading"
          class="flex flex-shrink"
        >
          <div
            class="flex-shrink bg-il-teal-faded hover:bg-il-teal text-white text-sm p-2 transition-all duration-200 ease-in-out cursor-pointer"
            @click="updateCompanyName"
          >
            <i class="fas fa-check-circle"></i>
          </div>
          <div
            class="flex-shrink bg-red-300 hover:bg-red-600 text-white text-sm p-2 rounded-r-lg transition-all duration-200 ease-in-out cursor-pointer"
            @click="companyNameEditToggle"
          >
            <i class="fas fa-times-circle"></i>
          </div>
        </div>
        <div
          v-else
          class="bg-il-teal text-white p-2 px-4 text-sm rounded-r-lg"
        >
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>
      </div>
    </div>

    <div class="text-center text-gray-400 uppercase text-sm mt-2">
      Use this page to set up your company Branding
    </div>

    <div class="flex flex-wrap mt-4">

      <div class="flex flex-col w-full lg:w-6/12 items-center p-4">
        <div
          class="text-white bg-il-dark text-center font-bold text-xl p-2 uppercase w-full rounded-t-lg"
        >
          Default Invoice Details
        </div>

        <div class="flex flex-col items-center p-2 shadow-xl w-full rounded-b-lg mb-4">
          <Logo
            class="mt-8"
            v-model:companyLogo="logo"
          />

          <div class="flex flex-col w-full">
            <From
              :key="getCompanySwitchKeyX"
              v-model:name="fromName"
              v-model:address="fromAddress"
            />
          </div>

          <div class="mt-4 mb-4">
            <div
              class=" py-2 px-4 mt-2 uppercase rounded-lg text-sm border border-il-dark transition-all duration-200 ease-in-out"
              :class="updateLoading ? 'bg-il-dark text-white' : 'text-il-dark bg-white cursor-pointer hover:bg-il-dark hover:text-white'"
              @click="updateCompany"
            >
              <span
                v-if="!updateLoading"
              >
                Update Details
              </span>
              <span v-else>
                <i class="fas fa-circle-notch fa-spin"></i>
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import companyRequests from '../../helpers/companyRequests';
  import { mapGetters, mapMutations } from 'vuex';
  import Logo from '../../components/CompanySettings/Logo';
  import From from '../../components/CompanySettings/From';
  import lodash from 'lodash';

  export default {
    mounted() {
      this.fromName = this.getUserCurrentCompanyX.from_name;
      this.fromAddress = this.getUserCurrentCompanyX.from_address;
      this.logo = this.getUserCurrentCompanyX.logo_url;
    },
    data() {
      return {
        editingCompanyName: false,
        companyNewName: null,
        logo: null,
        fromName: null,
        fromAddress: null,
        updateLoading: false,
      }
    },
    components: {
      Logo,
      From
    },
    methods: {
      ...mapMutations([
        'setUserCurrentCompanyNameX',
        'setUserCurrentCompanyX'
      ]),
      companyNameEditToggle() {
        this.editingCompanyName = !this.editingCompanyName;

        if(this.editingCompanyName) {
          this.companyNewName = this.getUserCurrentCompanyX.name;
          this.$nextTick(function() {
            this.$refs.companyNewNameInput.focus();
          });
        } else {
          this.companyNewName = null;
        }
      },
      updateCompanyName() {
        if(!this.updateLoading) {
          this.updateLoading = true;
          companyRequests.updateCompanyName(this.companyNewName)
          .then((response) => {
            this.setUserCurrentCompanyNameX(this.companyNewName);
            this.updateLoading = false;
            this.editingCompanyName = false;
            this.$toast.success(response.data.message, {
              position: "bottom"
            });
          }).catch(error => {
            console.log(error);
            this.$toast.error(error.response.data.message, {
              position: "bottom"
            });
            this.updateLoading = false;
          });
        }
      },
      updateCompany() {
        if(!this.updateLoading) {
          this.updateLoading = true;
          const companyData = {
            logo: this.logoString,
            logo_extension: this.logoExtension,
            from_name: this.fromName,
            from_address: this.fromAddress
          }

          companyRequests.updateCompany(companyData)
          .then((response) => {
            this.setUserCurrentCompanyX(response.data.current_company);
            this.updateLoading = false;
            this.popSuccessMessageMX(response.data.message);
          }).catch(error => {
            console.log(error);
            this.popErrorsMX(error.response.data.errors);
            this.updateLoading = false;
          });
        }
      },
      goBack() {
        this.$router.go(-1);
      }
    },
    computed: {
      ...mapGetters([
        'getUserCurrentCompanyX',
        'getCompanySwitchKeyX'
      ]),
      logoString() {
        if(this.logo) {
          return this.logo.substring(this.logo.indexOf(",") + 1);
        }
        return null;
      },
      logoExtension() {
        if(this.logo) {
          return this.logo.substring(this.logo.indexOf(":image/") + 7,this.logo.lastIndexOf(";"));
        }
        return null;
      },
    },
    watch: {
      getCompanySwitchKeyX: function (val) {
        this.fromName = this.getUserCurrentCompanyX.from_name;
        this.fromAddress = this.getUserCurrentCompanyX.from_address;
        this.logo = this.getUserCurrentCompanyX.logo_url;
      }
    }
  }
</script>