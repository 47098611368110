<template>

  <div class="text-il-dark font-bold uppercase text-xs mt-4">
    From
  </div>
  <textarea
    ref="nameRef"
    class="text-lg outline-none border border-gray-400 focus:border-il-dark px-2 py-1 transition-all duration-200 ease-in-out"
    rows='1'
    data-min-rows='1'
    data-height-step='28'
    :value="name"
    @input="updateValue('name', $event.target, 28)"
  >
  </textarea>

  <div class="text-il-dark font-bold uppercase text-xs mt-4">
    contact details
  </div>
  <textarea
    ref="addressRef"
    class="text-lg outline-none border border-gray-400 focus:border-il-dark px-2 py-1 transition-all duration-200 ease-in-out"
    rows='1'
    data-min-rows='1'
    data-height-step='28'
    :value="address"
    @input="updateValue('address', $event.target, 28)"
  >
  </textarea>

</template>


<script>
  import { mapGetters, mapMutations } from 'vuex';
  export default {
    props: ['name', 'address'],
    emits: [
      'update:name',
      'update:address'
    ],
    mounted() {
      this.$nextTick(function() {
        this.autoResizeMX(this.$refs.nameRef,28);
        this.autoResizeMX(this.$refs.addressRef,24);
      });
    },
    data() {
      return {

      }
    },
    methods: {
      ...mapMutations([
        'setFromNameX',
        'setFromAddressX'
      ]),
      updateValue(prop,target,heightStep) {
        this.autoResizeMX(target,heightStep);
        this.$emit('update:'+prop,target.value);
      }
    },
    computed: {
      ...mapGetters([

      ]),
    }
  }
</script>